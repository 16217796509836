.skills-card{
    background-color: var(--color-surface-200);
    width: 100%;
}

.skills-inner-card{
    background-color: var(--color-surface-300);
}

.language-logo {
    width: 80%;
}