.github-card {
    background-color: var(--color-surface-200);
    width: 100%;
}

.github-icon {
    color: white;
    font-size: 150px;
}

.github-btn {
    color: white;
    background-color: transparent;
    border-color: white;
    border-width: 2px;
}

.github-btn:hover {
    background-color: transparent;
    color: var(--color-primary-500) !important;
    border-color: var(--color-primary-500);
}

.carousel-caption {
    position: static;
}

.enlarge-image {
    cursor: pointer;
    max-width: 100%;
    height: auto;
}
