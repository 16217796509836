.display-5 {
    color: var(--color-surface-300);
    opacity: 0;
}

.revealed {
    animation-name: reveal-opacity;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

.jsgif {
    display: none;
}

@keyframes reveal-opacity {
    from {opacity: 0}
    to {opacity: 100%}
}

.revealed-slidedown {
    animation-name: reveal-opacity-slideD;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

@keyframes reveal-opacity-slideD {
    from {
        opacity: 0;
        transform: translateY(-30%);
    }
    to {
        opacity: 100%;
        transform: translateY(0%);
    }
}

#animoji {
    width: 300px;
    z-index: 1;
    position: absolute;
}

.animojiAnimation {
    animation-name: animoji-movement;
    animation-duration: 18550ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

@keyframes animoji-movement {
    0%{
        transform: translateX(0%) translateY(0%) scale(0);
        z-index: 3;
    }
    5%{
        transform: translateX(0%) translateY(-80%) scale(1);
    }
    10%{
        opacity: 1;
    }
    22%{
        transform: translateX(0%) translateY(-80%);
    }
    29%{
        transform: translateX(-100%) translateY(100%);
        z-index: 3;
    }
    32%{
        transform: translateX(-100%) translateY(100%);
    }
    40%{
        transform: translateX(100%) translateY(80%);
    }
    42%{
        transform: translateX(100%) translateY(80%);
    }
    47%{
        transform: translateX(-100%) translateY(-80%);
    }
    50%{
        transform: translateX(-100%) translateY(-80%);
    }
    55%{
        transform: translateX(120%) translateY(-80%);
    }
    60%{
        transform: translateX(120%) translateY(-80%);
    }
    66%{
        transform: translateX(0%) translateY(-80%);
    }
    75%{
        transform: translateX(0%) translateY(-80%);
    }
    80%{
        transform: translateX(150%) translateY(0%);
        z-index: 3;
    }
    87%{
        transform: translateX(150%) translateY(0%);
        z-index: 3;
    }
    90%{
        transform: translateX(-150%) translateY(-50%);
        z-index: 1;
    }
    99%{
        transform: translateX(-150%) translateY(-50%);
        z-index: 1;
    }
    100%{
        opacity: 1;
        transform: translateX(-150%) translateY(-50%) scale(0);
    }
}

@keyframes animoji-movement-lg {
    0%{
        transform: translateX(0%) translateY(0%) scale(0);
        z-index: 3;
    }
    5%{
        transform: translateX(0%) translateY(-120%) scale(1);
    }
    10%{
        opacity: 1;
    }
    22%{
        transform: translateX(0%) translateY(-120%);
    }
    29%{
        transform: translateX(-100%) translateY(140%);
        z-index: 3;
    }
    32%{
        transform: translateX(-100%) translateY(140%);
    }
    40%{
        transform: translateX(100%) translateY(115%);
    }
    42%{
        transform: translateX(100%) translateY(115%);
    }
    47%{
        transform: translateX(-120%) translateY(-80%);
    }
    50%{
        transform: translateX(-120%) translateY(-80%);
    }
    55%{
        transform: translateX(120%) translateY(-80%);
    }
    60%{
        transform: translateX(120%) translateY(-80%);
    }
    66%{
        transform: translateX(0%) translateY(-120%);
    }
    75%{
        transform: translateX(0%) translateY(-120%);
    }
    80%{
        transform: translateX(120%) translateY(0%);
        z-index: 3;
    }
    87%{
        transform: translateX(120%) translateY(0%);
        z-index: 3;
    }
    90%{
        transform: translateX(-120%) translateY(-50%);
        z-index: 1;
    }
    99%{
        transform: translateX(-120%) translateY(-50%);
        z-index: 1;
    }
    100%{
        opacity: 1;
        transform: translateX(-120%) translateY(-50%) scale(0);
    }
}

@keyframes animoji-movement-md {
    0%{
        transform: translateX(0%) translateY(0%) scale(0);
        z-index: 3;
    }
    5%{
        transform: translateX(0%) translateY(-140%) scale(1);
    }
    10%{
        opacity: 1;
    }
    22%{
        transform: translateX(0%) translateY(-140%);
    }
    29%{
        transform: translateX(-100%) translateY(140%);
        z-index: 3;
    }
    32%{
        transform: translateX(-100%) translateY(140%);
    }
    40%{
        transform: translateX(100%) translateY(115%);
    }
    42%{
        transform: translateX(100%) translateY(115%);
    }
    47%{
        transform: translateX(-120%) translateY(-80%);
    }
    50%{
        transform: translateX(-120%) translateY(-80%);
    }
    55%{
        transform: translateX(120%) translateY(-80%);
    }
    60%{
        transform: translateX(120%) translateY(-80%);
    }
    66%{
        transform: translateX(0%) translateY(-140%);
    }
    75%{
        transform: translateX(0%) translateY(-140%);
    }
    80%{
        transform: translateX(120%) translateY(0%);
        z-index: 3;
    }
    87%{
        transform: translateX(120%) translateY(0%);
        z-index: 3;
    }
    90%{
        transform: translateX(-120%) translateY(-50%);
        z-index: 1;
    }
    99%{
        transform: translateX(-120%) translateY(-50%);
        z-index: 1;
    }
    100%{
        opacity: 1;
        transform: translateX(-120%) translateY(-50%) scale(0);
    }
}

@keyframes animoji-movement-sm {
    0%{
        transform: translateX(0%) translateY(0%) scale(0);
        z-index: 3;
    }
    5%{
        transform: translateX(0%) translateY(-130%) scale(1);
    }
    10%{
        opacity: 1;
    }
    22%{
        transform: translateX(0%) translateY(-130%);
    }
    29%{
        transform: translateX(-70%) translateY(140%);
        z-index: 3;
    }
    32%{
        transform: translateX(-70%) translateY(140%);
    }
    40%{
        transform: translateX(70%) translateY(115%);
    }
    42%{
        transform: translateX(70%) translateY(115%);
    }
    47%{
        transform: translateX(-70%) translateY(-50%);
    }
    50%{
        transform: translateX(-70%) translateY(-50%);
    }
    55%{
        transform: translateX(80%) translateY(-60%);
    }
    60%{
        transform: translateX(80%) translateY(-60%);
    }
    66%{
        transform: translateX(0%) translateY(-130%);
    }
    75%{
        transform: translateX(0%) translateY(-130%);
    }
    80%{
        transform: translateX(70%) translateY(-30%);
        z-index: 3;
    }
    87%{
        transform: translateX(70%) translateY(-30%);
        z-index: 3;
    }
    90%{
        transform: translateX(-70%) translateY(-50%);
        z-index: 1;
    }
    99%{
        transform: translateX(-70%) translateY(-50%);
        z-index: 1;
    }
    100%{
        opacity: 1;
        transform: translateX(-70%) translateY(-50%) scale(0);
    }
}

#mainHeading {
    z-index: 2;
}


@media only screen and (max-width: 768px) {
    #animoji {
        width: 200px;
    }
    .animojiAnimation {
        width: 200px;
        z-index: 1;
        position: absolute;
        animation-name: animoji-movement-md;
        animation-duration: 18550ms;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        left: 25%;
        top: 30%;
    }
}

@media only screen and (max-width: 568px) {
    #animoji {
        width: 200px;
    }
    .animojiAnimation {
        width: 200px;
        z-index: 1;
        position: absolute;
        animation-name: animoji-movement-sm;
        animation-duration: 18550ms;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        left: 25%;
        top: 30%;
    }
}

#waving:before{
    content: '\01F44B \01F3FB';
}
