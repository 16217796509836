.dot{
    position: absolute;
    background-color: white;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    border-radius: 15px;
}

#dot-second{
    top: 50px;
}

.line{
    position: relative;
    width: 3px;
    height: 100%;
    background-color: white;
}

.line-last {
    min-height: 150px;
    -webkit-mask-image: -webkit-gradient(linear, left top,
    left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    -webkit-mask-size: 100% 90%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: left top, left bottom;
}

.timeline-card{
    position: relative;
    background-color: var(--color-surface-200);
    width: 100%;
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
}

.timeline-card-r:before {
    content: "";
    position: absolute;
    top: 288px;
    margin-top: -15px;
    left: 100%;
    border: solid 15px transparent;
    border-left-color: var(--color-surface-200);
    z-index: 1;
}

#timeline-card-container-0 {
    margin-top: -116px;
}

#timeline-date-0 {
    writing-mode: vertical-lr;
    transform: translateY(-120px);
}

.timeline-card-l:before {
    content: "";
    position: absolute;
    top: 124px;
    margin-top: -15px;
    left: 100%;
    border: solid 15px transparent;
    border-left-color: var(--color-surface-200);
    z-index: 1;
}

#timeline-card-container-1 {
    margin-top: 50px;
}

#timeline-dot-1 {
    margin-top: 331px;
}

#timeline-date-1 {
    writing-mode: vertical-lr;
    transform: translateY(-90px);
}

.timeline-card-l-2:before {
    content: "";
    position: absolute;
    top: 150px;
    margin-top: -15px;
    left: 100%;
    border: solid 15px transparent;
    border-left-color: var(--color-surface-200);
    z-index: 1;
}

#timeline-card-container-2 {
    margin-top: 50px;
}

#timeline-dot-2 {
    margin-top: 193px;
}

#timeline-date-2 {
    writing-mode: vertical-lr;
    transform: translateY(72px);
}

#timeline-date-3 {
    writing-mode: vertical-lr;
    transform: translateY(-300px);
}

#timeline-container{
    margin-top: 30vh;
}

.timeline-date-container {
    margin: 0 15px 15px 15px;
}

@media only screen and (min-width: 1200px) {
    .timeline-card-r:before {
        content: "";
        position: absolute;
        top: 288px;
        margin-top: -15px;
        right: 100%;
        left: -100%;
        border: solid 15px transparent;
        border-right-color: var(--color-surface-200);
        z-index: 1;
    }

    #timeline-card-container-0 {
        margin-top: -116px;
    }

    #timeline-date-0 {
        margin-top: -6px;
        writing-mode: revert;
        transform: revert;
    }

    .timeline-card-l:before {
        content: "";
        position: absolute;
        top: 124px;
        margin-top: -15px;
        left: 100%;
        border: solid 15px transparent;
        border-left-color: var(--color-surface-200);
        z-index: 1;
    }

    #timeline-card-container-1 {
        margin-top: -140px;
    }

    #timeline-dot-1 {
        margin-top: 140px;
    }

    #timeline-date-1 {
        margin-top: 135px;
        writing-mode: revert;
        transform: revert;
    }

    .timeline-card-l-2:before {
        content: "";
        position: absolute;
        top: 273px;
        margin-top: -15px;
        left: 100%;
        border: solid 15px transparent;
        border-left-color: var(--color-surface-200);
        z-index: 1;
    }

    #timeline-card-container-2 {
        margin-top: -116px;
    }

    #timeline-dot-2 {
        margin-top: 150px;
    }

    #timeline-date-2 {
        margin-top: 144px;
        writing-mode: revert;
        transform: revert;
    }

    #timeline-date-3 {
        margin-top: 134px;
        writing-mode: revert;
        transform: revert;
    }
}

