#propic {
 clip-path: circle();
}

#card-presentation {
    background-color: var(--color-surface-200);
}

.p-info{
    color: var(--color-surface-500);
}