.contact-card {
    background-color: var(--color-surface-200);
    position: relative;
}

.email-input {
    background-color: var(--color-surface-300);
}

.textarea-input {
    background-color: var(--color-surface-300);
    height: 200px !important;
}

.send-btn {
    border-radius: 50px;
    background-color: var(--color-primary-500);
    border-width: 0;
    color: var(--color-surface-100);
    font-weight: bold;
    padding: 10px 15px 10px 15px;
}

#bubble-mail-container {
    border: red 3px;
}

#bubble-mail {
    margin-top: -50%;
    background-color: var(--color-surface-200);
}
