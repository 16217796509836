.btn-primary {
    border-radius: 50px;
    background-color: var(--color-primary-500);
    border-width: 0;
    color: var(--color-surface-100);
    font-weight: bold;
    padding: 10px 15px 10px 15px;
    opacity: 0;
}

.btn-primary:active {
    background-color: var(--color-surface-500) !important;
}

.btn-outline-primary {
    border-radius: 50px;
    border-width: 0;
    color: var(--color-primary-500);
    font-weight: bold;
    padding: 10px 15px 10px 15px;
}

.btn-primary:hover {
    background-color: var(--color-primary-100);
}