:root {
  /** CSS DARK THEME PRIMARY COLORS */
  --color-primary-100: #009688;
  --color-primary-200: #3ba294;
  --color-primary-300: #59ada1;
  --color-primary-400: #73b9ae;
  --color-primary-500: #8bc5bb;
  --color-primary-600: #a3d0c8;
  /** CSS DARK THEME SURFACE COLORS */
  --color-surface-100: #121212;
  --color-surface-200: #282828;
  --color-surface-300: #3f3f3f;
  --color-surface-400: #575757;
  --color-surface-500: #717171;
  --color-surface-600: #8b8b8b;
  /** CSS DARK THEME MIXED SURFACE COLORS */
  --color-surface-mixed-100: #171e1d;
  --color-surface-mixed-200: #2c3332;
  --color-surface-mixed-300: #434948;
  --color-surface-mixed-400: #5b605f;
  --color-surface-mixed-500: #747978;
  --color-surface-mixed-600: #8e9291;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-surface-100);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.display-2 {
  color: white;
}

#box1 {
  height: 100vh;
}

.hidden {
  opacity: 0;
  transition: all 1s;
}

.show {
  opacity: 1;
}

.always-show {
  opacity: 1 !important;
}

.mar-bottom{
  margin-bottom: 50px;
}

.more-mar-bottom {
  margin-bottom: 100px;
}

@media only screen and (min-width: 1200px) {
  .mar-bottom{
    margin-bottom: 100px;
  }
}